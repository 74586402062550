
import React from 'react';
import styles from './AmericanBluesNavbar.module.css';
import ottoackermanLogo from '../company_images/images/americanblueslogo.png'


const AmericanBluesNavbar = () => {
    return (
      <nav className='navbar'>
        <div className='navbar-container'>
         <img src={ottoackermanLogo} alt="Description of the image"
         className={styles.logoStyle} />
        </div>
      </nav>
    );
  };
  
export default AmericanBluesNavbar;
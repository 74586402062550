import React from 'react';
import { useNavigate } from 'react-router-dom';
import card1 from './images/ab.png';
import card2 from './images/ottoackerman.png';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const images = [
  { id: 1, src: card1, alt: 'Image 1', path: '/american_blues', width: '150px'},
  // { id: 2, src: card2, alt: 'Image 2', path: 'https://ottoackerman.com/',  width: '240px' },
];

const Card = ({ src, alt, path, width }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: width,
          height: '200px',
          borderRadius: '25px',
          objectFit: 'contain'
        }}
      />

    </div>
  );
};

const CardRow = () => (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '150px', paddingTop: '20px' }}>
    {images.map((image) => (
      <div key={image.id}>
        <Card src={image.src} alt={image.alt} path={image.path} width={image.width} />
      </div>
    ))}
    <div style={{paddingTop: '30px'}}> 
      <a href='https://ottoackerman.com/'>
      <img src={card2} alt="image" width="250px" />
      </a>
      </div>
  </div>
);

export default function BrandCards() {
  const quotes = '"To pull off any look, wear it with confidence." - Diane von Furstenberg';

  return (
    <div style={{ textAlign: 'center' }}>
      <h1
        style={{
          textAlign: 'center',
          fontFamily: 'taviraj-regular, sans-serif',
          fontSize: '35px',
          fontWeight: '600',
          color: '#242424',
          marginTop: '50px',
          marginBottom: '0',
        }}
      >
        Our Brands
      </h1>
      <CardRow />
      <p
        style={{
          textAlign: 'center',
          fontSize: '30px',
          marginTop: '50px',
          marginBottom: '50px',
          fontStyle: 'italic',
          color: '#242424'
        }}
      >
        {quotes}
      </p>
    </div>
  );
}

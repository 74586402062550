import React from 'react';
import icon01 from './images/icons/environment1.svg';
import icon02 from './images/icons/quality1.svg';
import icon03 from './images/icons/innovation.svg';
import icon04 from './images/icons/verified.svg';

const CardContainer = () => {
  const headingStyle = {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: '40px',
    color: '#242424',
    fontWeight: '700',
    margin: '20px 0',
  };

  const subStyle = {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
    color: '#242424',
    margin: '20px 10%',
  };

  const cardStyle = {
    margin: '20px',
    width: '270px',
    height: '300px',
    position: 'relative',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    flex: '0 0 auto',
  };

  const iconStyle = (bgColor) => ({
    height: '70px',
    width: '70px',
    position: 'absolute',
    top: '20px',
    left: '20px',
    background: bgColor,
    padding: '8px 12px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const contentStyle = {
    marginTop: '30px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  const titleStyle = {
    textAlign: 'left',
    fontFamily: 'Arial, sans-serif',
    fontSize: '17px',
    color: '#242424',
    fontWeight: '700',
  };

  const quoteStyle = {
    textAlign: 'left',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    marginTop: '20px',
    color: '#242424',
  };

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#d9f3ff',
    padding: '20px',
  };

  const cardsWrapperStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    overflowX: 'auto',
  };

  const mediaQueryStyles = window.matchMedia('(max-width: 768px)').matches
    ? {
        subStyle: {
          ...subStyle,
          margin: '0 5%',
        },
        cardStyle: {
          ...cardStyle,
          width: '90%',
          margin: '10px',
        },
        cardsWrapperStyle: {
          ...cardsWrapperStyle,
          flexDirection: 'column',
          alignItems: 'center',
        },
      }
    : {
        subStyle,
        cardStyle,
        cardsWrapperStyle,
      };

  return (
    <div style={containerStyle}>
      <div>
        <h1 style={headingStyle}>What We Do</h1>
        <p style={mediaQueryStyles.subStyle}>We lead the way in sustainable fashion by producing clothing made from organic cotton, free from harmful pesticides and chemicals. Our mission is to create a healthier world for current and future generations through environmentally responsible practices</p>
        <div style={mediaQueryStyles.cardsWrapperStyle}>
          <div style={mediaQueryStyles.cardStyle}>
            <div style={iconStyle('linear-gradient(143deg, rgba(82, 194, 52, 1) 0%, rgba(12, 57, 0, 1) 89%)')}>
              <img src={icon01} alt="Environmental Champions" />
            </div>
            <div style={contentStyle}>
              <h3 style={titleStyle}>Environmental Champions</h3>
              <p style={quoteStyle}>Leading the charge towards a greener future through sustainable practices</p>
            </div>
          </div>
          <div style={mediaQueryStyles.cardStyle}>
            <div style={iconStyle('linear-gradient(143deg, rgba(0, 0, 255, 1) 0%, rgba(0, 0, 128, 1) 100%)')}>
              <img src={icon02} alt="Uncompromised Quality" />
            </div>
            <div style={contentStyle}>
              <h3 style={titleStyle}>Uncompromised Quality</h3>
              <p style={quoteStyle}>Crafting premium clothing with meticulous attention to detail and unwavering quality standards</p>
            </div>
          </div>
          <div style={mediaQueryStyles.cardStyle}>
            <div style={iconStyle('linear-gradient(143deg, rgba(255, 0, 0, 1) 0%, rgba(128, 0, 0, 1) 100%)')}>
              <img src={icon03} alt="Innovation Hub" />
            </div>
            <div style={contentStyle}>
              <h3 style={titleStyle}>Innovation Hub</h3>
              <p style={quoteStyle}>Where tradition meets innovation, we're constantly pushing boundaries in design and production</p>
            </div>
          </div>
          <div style={mediaQueryStyles.cardStyle}>
            <div style={iconStyle('linear-gradient(143deg, rgba(148, 0, 211, 1) 0%, rgba(75, 0, 130, 1) 100%)')}>
              <img src={icon04} alt="Direct-to-Consumer Advantage" />
            </div>
            <div style={contentStyle}>
              <h3 style={titleStyle}>Direct-to-Consumer Advantage</h3>
              <p style={quoteStyle}>By cutting out the middlemen, we deliver top-notch quality at unbeatable prices.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;

import React from 'react';
import { Link } from 'react-router-dom';

import AmericanBluesCarousel from './AmericanBluesCarousel';
import AmericanBluesFooter from './AmericanBluesFooter';
import AmericanBluesNavBar from './AmericanBluesNavBar'
import AmericanBluesBrandCards from './AmericanBluesBrandcards';
import { text } from '@fortawesome/fontawesome-svg-core';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import AboutUs from './AboutUs'

const productStyle = {
  textAlign: 'center',
  fontFamily: 'Arial, san-serif'
};

const aboutUs = {
  textAlign: 'center',
  fontFamily: 'Arial, san-serif'
};

const AmericanBlues = () => {
  return (
    <div>
      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
          <AmericanBluesNavBar />
      </div>
      <AmericanBluesCarousel />
     <AboutUs />
      {/* <AmericanBluesBrandCards /> */}
      <AmericanBluesFooter />
    </div>
  );
};

export default AmericanBlues;

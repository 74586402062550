import React from 'react';
import { Carousel } from 'antd';
// import carousel01 from "./images/carousel/boy.jpg"
import carousel02 from "./images/carousel/covid.jpg"
import carousel03 from "./images/carousel/shopping.jpg"
import carousel04 from "./images/carousel/carousel01.jpg"

// Import the PNG image file

const contentStyle = {
  height: '850px',
  width: "100%",
  textAlign: 'center',
  objectFit: 'cover'
};

const App = () => (
  <Carousel autoplay>
    
    <div>

      <img src={carousel04} alt="Image 1" style={contentStyle} /> {/* Use the imported PNG image */}
    </div>
    <div>
      <h3 style={contentStyle}>
      <img src={carousel02} alt="Image 1" style={contentStyle} /> {/* Use the imported PNG image */}

      </h3>
    </div>
    <div>
      <h3 style={contentStyle}>
      <img src={carousel03} alt="Image 1" style={contentStyle} /> {/* Use the imported PNG image */}

      </h3>
    </div>
    <div>
      <h3 style={contentStyle}>
      <img src={carousel04} alt="Image 1" style={contentStyle} /> {/* Use the imported PNG image */}

      </h3>
    </div>
  </Carousel>
);

export default App;

import React from 'react';
import './Footer.css';
import x from './images/x.png';
import insta from './images/insta.png';
import fb from './images/fb.png';
import { Col, Row } from 'antd';

const contact = {
  color: 'white'
};

const Footer = () => (
  <footer className="footer">

    <Row>
      <Col span={8}> 
       <div className='brand'>
        
        <h1 className='brand-name'>World of Ecstasy</h1>
        <p className='brand-address'>203, Barley lane Chadwell Heath Romford</p>
       
      </div></Col>
      <Col span={8}><div>

        <div className="footer-icon">
          <p className='icon-heading'>Follow us on</p>
          <div className='icons'>
          <a href="https://www.facebook.com/share/MfNqd4GBaV8BJ8p1/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="Image 3" className="footer-image" />
          </a>
          <a href="https://x.com/worldofecstacy" target="_blank" rel="noopener noreferrer">
              <img src={x} alt="Image 1" className="footer-image" />
          </a>
          <a href="https://www.instagram.com/world_of_ecstacy?igsh=enFhejkwamt4Ym4z&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Image 2" className="footer-insta" />
          </a>
          </div>
        </div>
      </div>
      </Col>

      <Col span={8}>
        <p className='contact' > 
          <a href='mailto:contact@worldofecstasy.com' style={contact}>contact@worldofecstasy.com</a>
        </p>

     </Col>
    </Row>

  </footer>
);

export default Footer;

import React from 'react';
import img from './images/landing.jpeg'

function AboutUs() {
  return (
    <div style={{ display: 'flex',
      // borderRadius: '15px',
       overflow: 'hidden',
      //  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',       
       marginBottom: '100px',
       marginRight: '100px',
       marginLeft: '100px',
       marginTop: '100px',
        }}>
      <div style={{ flex: 1.5}}>
        <img src={img} alt="Placeholder" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
      </div>
      <div style={{ flex: 2, padding: '20px', fontFamily: 'Arial, sans-serif', fontWeight: 600, color: '#242424' }}>
        <h2 style={{ fontSize: '3.5rem', marginBottom: '10px' }}>About Us</h2>
        <p style={{ fontSize: '1.2rem', color: '#666', fontWeight: 100}}>
        World of Ecstacy is a registered clothing line brand in India. It was founded in 2016.
        We specialize in manufacturing a wide range of Schools, Colleges, Corporates,
        Industries, Hospitals and Hotel uniforms.
        Client satisfaction is our preset goal of supplying a huge range of products. We are
        providing the best quality and class product in accordance with the latest of skilled and
        capable professionals who work in a cohesive manner with one another for the stream
        line functioning of the business and to attain maximum client satisfaction.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;

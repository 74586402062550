import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core'; 
import ImageCarousel from './components/Carousel';
import Brands from './components/Brands';
import AmericanBluesPage from './components/companies/AmericanBlues/AmericanBlues';
import BuffaloBoulevardPage from './components/companies/BuffaloBoulevard';
import OttoAckermanPage from './components/companies/OttoAckerman/OttoAckerman';
import Layout from './components/Layout';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Card from './components/CardComponent'
import AboutUs from './components/AboutUs'

function App() {
  return (
    <MantineProvider> 
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
        <div>
          <div  style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
          <Navbar />
          </div>
          <ImageCarousel />
          <AboutUs />
          <Card />
          <Brands />
          <Footer />
        </div>
        } />
        <Route path="/american_blues" element={<AmericanBluesPage />} />
        {/* <Route path="/otto_ackerman" element={<OttoAckermanPage />} />~ */}
        <Route path="/buffalo_boulevard" element={<BuffaloBoulevardPage />} />
      </Routes>
    </BrowserRouter>
    </MantineProvider> 
  );
}

export default App;
import React from 'react';
import styles from './AmericanBluesFooter.module.css'; // Import your custom CSS file for styling
import x from '../company_images/images/x.png';
import insta from '../company_images/images/insta.png';
import fb from '../company_images/images/fb.png';
import { Col, Row } from 'antd';

const contact = {
  color: 'white'
};

const Footer = () => (
  <footer className={styles.footer}>

    <Row>
      <Col span={8}> 
       <div className={styles.brand}>
        
        <h1 className={styles.brandname}>American Blues</h1>
        <p className={styles.brandaddress}>203, Barley lane Chadwell Heath Romford</p>
       
      </div></Col>
      <Col span={8}>
        <div>
          <div className={styles.footericon}>
            <p className={styles.iconheading}>Follow us on</p>
              <div className={styles.icons}>
                <img src={fb} alt="Image 3" className={styles.footerimage} />
                <img src={x} alt="Image 1" className={styles.footerimage} />
                <img src={insta} alt="Image 2" className={styles.footerinsta} />
              </div>
          </div>
        </div>
      </Col>

      <Col span={8}>
    
      <p className='contact' > 
          <a href='mailto:contact@worldofecstasy.com' style={contact}>contact@americanblues.com</a>
        </p>
        
     </Col>
    </Row>

  </footer>
);

export default Footer;
